import React from 'react'
import {Layout} from "../components/Layout";
import {Col, Row} from "react-bootstrap";
import NavbarSpacer from "../components/NavbarSpacer";
import {BrightRow, DarkRow, GreenRow, MediumRow} from "../components/Rows";
import {RowClassesAlignCenter} from "../components/RowStyles";

export default function About(props) {
  return (
    <Layout>
      <GreenRow className={RowClassesAlignCenter}>
        <NavbarSpacer/>
        <Col md={{span: 3}}>
          <h3>Who is Hidden Switch?</h3>
        </Col>
        <Col md={{span: 9}} className="p-0 p-lg-4">
          <h2>A team of 9 full time engineers, artists, writers and musicians</h2>
          <h4>Directors</h4>
          <p>Benjamin Berman is an engineer and artist based on San Francisco. His interactives have been featured in
            the New York Times, Wired, the BBC and more; his animation has appeared in feature films by Errol Morris,
            Disney and the Tribeca Film Festival.</p>
          <p>James Gale is an engineer based in Seattle, Washington. He is a significant contributor to Spellsource and
            author of open source games and mods.</p>
          <p>Nolan Fabricius is an artist and longtime collaborator responsible for art in these games and more.</p>
          <p>Alexander Berman & Camille Stochitch write for Spellsource and Monster Match. They also write and direct
            for TV and features. They guide the community to author high-quality narratives to accompany the main plots
            and characters in Spellsource.</p>
        </Col>
      </GreenRow>
      <MediumRow className={RowClassesAlignCenter}>
        <Col md={{span: 3}}>
          <h3>Where is it located?</h3>
        </Col>
        <Col md={{span: 9}} className="p-0 p-lg-4">
          <h2>San Francisco, California</h2>
          <p>1460 Mission Street</p>
          <h2>Pasadena, California</h2>
          <p>177 East Colorado Boulevard</p>
          <h2>Brooklyn, New York</h2>
          <p>100 Bogart Street</p>
        </Col>
      </MediumRow>
    </Layout>
  )
}
